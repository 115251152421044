import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import Menu from "@localComponents/menu"
import Heading from "@localComponents/heading"
import Head from "@localComponents/head"
import Incompletion from "@localComponents/incompletion"
import Header from "@localComponents/header"
import { GlobalStyles, Main, Content, Body } from "./styles"
import UserContext from "@context/UserContext"
import { capitalize } from "@helpers/stringHelpers"
import { getEnvironment } from "@helpers/siteHelpers"
import { EnvironmentRibbon } from "@smaller-earth/postcard-components"

class PrivateLayout extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)

    this.state = {
      showMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  render() {
    const environment = getEnvironment()
    const { title, inProfileChecklist, children, simpleFormLayout, fullWidth } = this.props
    const { menu, user } = this.context
    const currentItem = menu.checklist.find((item) => item.system_name === this.props.systemName)

    return currentItem && !!currentItem.locked && !user.in_admin_view ? (
      <Redirect to="/403" />
    ) : (
      <>
        <Head title={title} />
        <GlobalStyles simpleForm={simpleFormLayout} />
        {environment !== "production" && <EnvironmentRibbon environment={capitalize(environment)} />}
        <Body>
          <Menu
            toggleMenu={this.toggleMenu}
            showMenu={this.state.showMenu}
            currentPageInProfileChecklist={inProfileChecklist}
          />
          <Content>
            <Header toggleMenu={this.toggleMenu} />
            <Main simpleForm={simpleFormLayout} fullWidth={fullWidth}>
              {title && <Heading simple={simpleFormLayout} title={capitalize(title)} />}
              {currentItem && <Incompletion message={currentItem.incompletion_message} />}
              {children}
            </Main>
          </Content>
        </Body>
      </>
    )
  }
}

PrivateLayout.defaultProps = {
  itemName: "",
  inProfileChecklist: false
}

export default PrivateLayout
